import React, { useState, useEffect } from 'react';
import { Image } from '@nextui-org/react';

const TextLooper: React.FC = () => {
    const names = ['Radhika', 'Aayush', 'Pooja', 'Amir'];
    const [currentNameIndex, setCurrentNameIndex] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentNameIndex((prevIndex) => (prevIndex + 1) % names.length);
        }, 3000); // Change every 3 seconds

        return () => clearInterval(interval);
    }, []);


    return (
        <>
         <div className="home-subtext libre-baskerville-italic text-center">
         An invite-only community for high-intentioned South Asians, like {names[currentNameIndex]}.
        </div>
        <div className="image-container flex">
                {names.map((_, index) => (
                    <img
                        
                        key={index}
                        src={require(`../Assets/app_user_${index}.png`)} // Replace with actual image paths
                        alt={`Image ${index + 1}`}
                        className={`image ${currentNameIndex === index ? 'active' : ''}`}
                    />
                ))}
            </div>
        </>
       
    );
};

export default TextLooper;
